.card-connection-container {
    margin-top: 3em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: 5px solid #33b864;
    padding: 1em;
    cursor: default;
    background-color: whitesmoke;
    color: black;
    width: 70em;
}

.card-connection-button-container {
    display: flex;
    flex-direction: column;
    width: 14em;
    text-decoration: none !important;
}

@media screen and (max-width: 1200px) {
    .card-connection-container {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .card-connection-container {
        flex-direction: column;
        font-size: 3vw;
        width: 90%;
        gap: 2em;
    }

    .card-connection-information h5 {
        font-size: 3vw;
    }

    .card-connection-information h6 {
        font-size: 2.5vw;
    }

    .card-connection-button-container {
        width: 60%;
    }

    .card-connection-button-container button {
        font-size: 3vw;
    }
}