.month-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.month-details {
    margin-top: 10px;
}

.past-worktime-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.past-worktime {
    width: 50%;
    border: 2px solid #000000; 
}

.no-active-worktime-account-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.listitem-secondary {
    display: flex;
    gap: 10px;
}

.month-details-listitem {
    /* margin-left: 30px !important; */
    padding-left: 40px !important;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.create-timeaccount-modal {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
}

.month-list-container .parent-list-all-months {
    width: 50%;
    margin-top: 30px !important;
    background-color: #f5f5f5;
}

.correction-secondary {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.dashboard-card-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width: 1200px) {
    .month-list-container .parent-list-all-months {
        width: 90% !important;
    }
}

@media screen and (max-width: 800px) {
    .dashboard-card-container {
        flex-direction: column;
        align-items: center;
    }

    .total-worktime-listitemtext span {
        width: 100px;
    }
}