.employee-list-container {
    margin-top: 2%;
    width: 100%;
    /* margin-bottom: 20%; */
}

a:hover {
    text-decoration: none;
}

.text-gray {
    color: gray;
}

@media screen and (max-width: 700px) {
    .employee-list-container {
        font-size: 70%;
    }
}