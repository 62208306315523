.shift-complaint-container {
    margin-top: 2em;
    margin-left: 1em;
    text-align: start;
}

.resolve-complaint-form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}