.live-header-container {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: 2em;
    align-items: center;
    vertical-align: middle;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-container h2 {
    margin-bottom: 0;
}

.gray {
    color: gray;
}

.refresh-button-container {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 700px) {
    .live-header-container {
        margin-top: 3%;
        flex-direction: column;
        align-items: flex-start;
    }

    .refresh-button-container {
        margin-top: 5%;
        justify-content: left;
    }

    .refresh-button-container button {
        font-size: 1vw;
    }
}

