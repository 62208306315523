.employee-header-container {
    margin-top: 4%;
    display: flex;
    margin-left: 9%;
    align-items: center;
}

.header-container {
    display: flex;
    justify-content: left;
}

.add-button-container {
    display: flex;
    justify-content: center;
    margin-top: 0;
}

.add-button-container button {
    font-size: 1.4em;
}

.csv-button-container {
    width: auto;
    margin-left: 1em;
    display: flex;
    justify-content: center;
}

.search-bar-container {
    font-size: 1em !important;
    width: 20em !important;
    margin-left: 2em;
    border: 1px solid gainsboro;
    padding: 0.2em;
    border-radius: 1em 1em 1em 1em;
}

.search-bar-container .form-control {
    border: 0 !important;
}

.search-bar-container .form-control:focus {
    box-shadow: none !important;
}

.search-bar-container .input-group-text {
    border: 0 !important;
}

.search-logo {
    width: 1.5em;
}

.input-group-text {
    background-color: white !important;
}

.mobile-top-header {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 700px) {
    .employee-header-container h1 {
        font-size: 150% !important;
    }

    .employee-header-container {
        margin-top: 10%;
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
    }

    .csv-button-container {
        margin-left: 10px;
    }

    .search-bar-container {
        /* margin-top: 3em; */
        margin-bottom: 20px;
        margin-left: 0;
        width: 80% !important;
        font-size: 3vw !important;
    }
}