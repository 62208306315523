.message-to-tablet-container {
    margin-top: 5em;
    margin-left: 7%;
}

.message-to-tablet-container h3 {
    margin-bottom: 0;
}

.message-to-tablet-container a {
    color: black;
}

.message-to-tablet-container .back-button {
    margin-bottom: 2em;
    font-size: 13px;
}

.message-to-tablet-header {
    display: flex;
    align-items: center;
    gap: 2em;
    margin-bottom: 1em;
}

.warning-update-qr-app {
    background-color: #f5f5f5;
    font-size: large;
    font-weight: bold;
}