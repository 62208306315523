.core-time-container {
    margin-top: 5em;
    margin-left: 7%;
}

.core-time-container h3 {
    margin-bottom: 0;
}

.core-time-container a {
    color: black;
}

.core-time-container .back-button {
    margin-bottom: 2em;
    font-size: 13px;
}

.core-time-header {
    display: flex;
    align-items: center;
    gap: 1.5em;
    margin-bottom: 1em;
}

@media only screen and (max-width: 1000px) {
    .core-time-header {
        flex-direction: column;
    }
}