.employee-item-container {
    display: flex;
    margin-top: 0.5%;
    border-left: 1px solid #343A40;
    margin-left: 7%;
    margin-right: 7%;
    font-size: 1.5em;
    cursor: pointer;
    height: 2em;
    align-items: center;
}

.warning {
    border: 3px solid #DE5757;
}

.employee-item-container:hover {
    background-color: whitesmoke;
}

.employee-item-text {
    text-align: left;
    margin-bottom: 0;
    margin-left: 1em;
    color: black;
}

.small-text {
    color: gray;
    font-size: 0.9em;
}

.warning-logo {
    width: 1em;
    height: 1em;
    padding-bottom: 1%;
}

