.shift-history-container {
    margin-top: 3em;
    margin-left: 7%;
    width: 30em;
}

@media screen and (max-width: 800px) {
    .shift-history-container {
        width: 80%;
    }
}

@media screen and (max-width: 400px) {
    .shift-history-container p {
        font-size: 4vw;
    }
}