.work-time-account-dasboard {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: 3em;
    margin-left: 5%;
    margin-right: 5%;
}

.work-time-account-dasboard .primary-button {
    max-width: fit-content;
}

.dashboard-datepicker {
    max-width: fit-content;
}

.work-time-account-dasboard .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}

.work-time-account-dasboard .MuiDataGrid-cell[data-field="bilance"] {
    font-weight: bold;
}

.grid-row-red-color {
    background-color: red;
    color: white;
}

.grid-row-yellow-color {
    background-color: yellow;
}