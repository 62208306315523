.location-view-header-container {
    display: flex;
    margin-left: 5%;
    margin-top: 3%;
    align-items: baseline;
}

.location-view-header-container p {
    font-size: 1.5em;
    margin-left: 3%;
}

.active-header {
    text-align: center;
    width: 10%;
    color: green;
    border-radius: 0.6em 0.6em 0.6em 0.6em;
}

.inactive-header {
    text-align: center;
    width: 10%;
    color: red;
    border-radius: 0.6em 0.6em 0.6em 0.6em;
}

.location-view-container {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    margin-bottom: 10%;
}

.location-information-container {
    background-color: whitesmoke;
    border-radius: 1em 1em 1em 1em;
    width: 40em;
}

.location-csv-container {
    background-color: white;
    border-radius: 1em 1em 1em 1em;
    width: 35%;
    height: auto;
}

@media screen and (max-width: 1200px) {
    .location-view-header-container {
        flex-direction: column;
        gap: 1em
    }

    .location-view-header-container p {
        width: 50%;
        margin-left: 0;
    }

    .location-information-container {
        width: 70%;
        margin-top: 10%;
    }
}

@media screen and (max-width: 500px) {
    .location-view-header-container p {
        width: 80%;
    }

    .location-information-container {
        width: 80%;
        margin-top: 20%;
    }
}