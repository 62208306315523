.all-employee-header {
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    margin-top: 3%;
    align-items: baseline;
}

.all-employee-header p {
    font-size: 1.5em;
    margin-left: 3%;
}

.switch-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-left: 30px;
}

.all-pdf-options-checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

@media screen and (max-width: 1200px) {
    .all-employee-header {
        margin-top: 10%;
        flex-direction: column;
        gap: 2em;
    }

    .all-employee-header p {
        margin-left: 0;
    }
}

@media screen and (max-width: 600px) {
    .all-employee-csv-header {
        font-size: 6vw;
    }
}