.location-list-container {
    margin-top: 2%;
    width: 100%;
    /* margin-bottom: 20%; */
}

.active-profile-header {
    font-size: 1.7em;
    margin-left: 9%;
    color: green;
}

.inactive-profile-header {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.7em;
    margin-left: 9%;
    color: red;
}

.text-gray {
    color: gray;
}

@media screen and (max-width: 700px) {
    .location-list-container {
        font-size: 70%;
    }
}