
.label-margin-top {
    margin-top: 10px;
}

.toggle-timeaccount-transfertime {
    margin-bottom: 30px;
}

.toggle-timeaccount-transfertime svg {
    margin-bottom: 0.5rem;
}

.create-timeaccount-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.create-timeaccount-add-time-label {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.create-timeaccount-add-time {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.delete-timeaccount-section {
    margin-top: 30px;
}
