.create-shift-location-input-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    height: 10em;
    width: 100%;
}

.create-shift-location-input-message {
    font-size: 1.3em;
    width: 80%;
}

.create-shift-selection {
    width: 80%;
}

.create-shift-selection button {
    width: 100%;
}

.create-shift-location-input-container button {
    color: black;
    font-size: 1.2em;
}

.rs-message-success .rs-message-body {
    color: black;
}

.rs-dropdown-menu {
    width: 80%;
}

@media only screen and (max-width: 1200px) {
    .create-shift-location-input-container {
        margin-top: 5vw;
        display: flex;
        flex-direction: column;
        gap: 2em;
        align-items: baseline;
        justify-content: center;
        font-size: 1.5vw;
    }

    .rs-dropdown-menu {
        width: 100%;
    }
}