.new-shift-summary-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    border-radius: 1em 1em 1em 1em;
    padding: 2em;
    width: 80%;
    background-color: white;
}

.green {
    color: green;
}

@media only screen and (max-width: 1200px) {

    .new-shift-summary-container {
        margin-bottom: 5em;
    }

    .new-shift-summary-container h5 {
        font-size: 3vw;
    }
}