.create-shift-begin-input-message {
    font-size: 1.3em;
    width: 80%;
}

.rs-message-info .rs-message-body {
    color: black !important;
}

.create-shift-datepicker {
    width: 80%;
}

@media only screen and (max-width: 1200px) {
    
}