.core-time-detail-view-container {
    margin-top: 5em;
    margin-left: 7%;
}

.core-time-detail-view-container button {
    font-size: small
}

.core-time-detail-view-container .back-button {
    margin-bottom: 2em;
}

.core-time-config-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.core-time-config-header button {
    height: fit-content;
}

.core-time-employees h4 {
    margin-top: 1em;
}

.core-time-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.core-time-weekday-times .MuiOutlinedInput-root {
    margin-right: 1em;
    margin-bottom: 1em;
}

.core-time-weekday-times h5 {
    margin-bottom: 1em;
}


@media only screen and (max-width: 1500px) {
    .core-time-content {
        justify-content: unset;
        flex-direction: column;
    }
    
    .core-time-employees {
        margin-top: 3em;
    }
}

@media only screen and (max-width: 1000px) {
    .core-time-config-header {
        justify-content: unset;
        gap: 1em;
    }
}