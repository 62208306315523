.download-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.export-switch-container {
    margin-top: 2em;
    display: flex;
    justify-content: center;
}

.year-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.export-year-select-formcontrol {
    width: 15%;
}

.pdf-options-checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.checkbox-switch-container {
    margin-top: 15px;
    margin-left: 30px;
    align-self: center;
}

.csv-checkbox-switch {
    display: flex;
    flex-direction: column;
}

.manual-pause-export-warning {
    margin-top: 2em;
    text-align: center;
    color: orange;
    padding-left: 1em;
    padding-right: 1em;
}

@media screen and (max-width: 1000px) {
    .export-form-selection {
        width: 80% !important;
    }

    .export-year-select-formcontrol {
        width: 35%;
    }
}
