.auto-pause-container {
    margin-top: 5em;
    margin-left: 7%;
}

.auto-pause-container h3 {
    margin-bottom: 0;
}

.auto-pause-container a {
    color: black;
}

.auto-pause-container .back-button {
    margin-bottom: 2em;
    font-size: 13px;
}

.auto-pause-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1em;
    margin-right: 20px;
}

.select-pausetime-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.button-group-pausetime {
    margin-top: -15px;
}

.add-remove-save-button {
    display: flex;
    gap: 10px;
}

.save-button {
    display: flex;
    margin-top: 20px;
    gap: 10px;
}

@media screen and (max-width: 500px) {
    .select-pausetime-container {
        gap: 5px;
    }
}