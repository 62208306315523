.card-access-header {
    margin-top: 3em;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 600px) {
    .card-access-header {
        flex-direction: column;
        gap: 1em;
    }

    .card-access-header h2 {
        font-size: 5vw;
    }

    .card-access-header button {
        font-size: 3vw;
        width: 80%;
    }
}