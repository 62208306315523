.edit-creating-pause-begin-input {
    width: 100% !important;
    margin-bottom: 2em;
}

.edit-creating-pause-end-input {
    width: 100% !important;
    margin-bottom: 2em;
}

.modal-creating-pause-info {
    color: var(--tictrack-secondary-color);
    margin-bottom: 2em;
}