.change-assignment-page {
    margin-top: 5em;
    margin-left: 7%;
    width: 80%;
}

.change-assignment-page button {
    font-size: small;
}

.change-assignment-page h4{
    margin-bottom: 1em;
}

.change-assignment-header {
    display: flex;
    gap: 2em;
    justify-content: flex-end;
    position: sticky;
    top: 10%;
    z-index: 100;
}

.blocked-employee-item {
    margin-top: 1em;
}

.red-color {
    color: red;
}

.modal-body strong {
    margin: 0;
}

