.all-activity-header {
    margin-left: 2em;
    margin-top: 5em;
}

.activities-header {
    display: flex;
    gap: 2em;
    margin-top: 10%;
    margin-bottom: 8%;
}

.activities-header h2 {
    margin-bottom: 0;
}

.shift-main-overview-container {
    display: flex;
    width: 100%;
}

.all-activity-container {
    display: flex;
    flex-direction: column;
    margin-left: 7%;
}

.activity-container {
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: whitesmoke;
    width: fit-content;
    padding: 0.7em;
    border-radius: 1em 1em 1em 1em;
}

.shift-additional-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.generated-pause-and-end-button button {
    width: 100%;
    margin-bottom: 2em;
}

.example-symbols {
    display: flex;
    flex-direction: column;
    font-size: 200%;
    align-items: center;
    margin-top: 10%;
}

.symbols-and-times {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
}

.symbols-and-times input {
    margin-left: 30%;
}

.arrow-down {
    height: 2em;
    width: 1.5em;
    margin-top: 2%;
}

.error-container {
    border: 1px solid #DE5757;
}

.live-shift-warning-text {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    color: red;
    font-weight: bold;
}

@media screen and (max-width: 1000px) {
    .shift-main-overview-container {
        flex-direction: column;
    }

    .generated-pause-and-end-button button {
        width: 24.5em;
    }
}

@media screen and (max-width: 600px) {

    .shift-main-overview-container {
        flex-direction: column;
    }

    .activities-header {
        margin-top: 20%;
        margin-bottom: 15%;
        flex-direction: column;
    }

    .activities-header button {
        width: 70%;
    }

    .activity-container {
        width: 90%;
        font-size: 3vw;
    }

    .generated-pause-and-end-button button {
        width: 90%;
    }

    .activity-container button {
        font-size: 3vw;
    }
}