.create-core-time-form {
    margin-top: 5em;
    margin-left: 7%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.create-core-time-form .MuiTextField-root {
    width: 30%;
}

.weekday-time-selection-container {
    display: flex;
    align-items: baseline;
}

.weekday-time-selection-container h5 {
    width: 10%;
}

.weekday-time-input-container {
    display: flex;
    width: 70%;
    align-items: baseline;
}

.weekday-time-input-container .first-input,.second-input {
    display: flex;
    align-items: baseline;
}

.weekday-time-input-container p, strong {
    margin-right: 1em;
    margin-left: 1em;
}

.weekday-time-input-container input {
    width: fit-content;
}

.weekday-time-input-container .add-mui-icon {
    margin-left: 3em;
}

.create-core-time-form button {
    align-self: flex-start;
    font-size: small;
}


@media only screen and (max-width: 1000px) {
    .create-core-time-form .MuiTextField-root {
        width: 70%;
    }

    .weekday-time-selection-container {
        flex-direction: column;
    }

    .weekday-time-input-container {
        width: fit-content;
        margin-top: 1em;
    }
}

@media only screen and (max-width: 800px) {

    .weekday-time-input-container {
        flex-wrap: wrap;
    }

    .weekday-time-input-container strong {
        display: none;
    }
}