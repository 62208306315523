.container a {
    text-decoration: none;
    color: white;
}

.navbar-nav {
    padding-left: 20% !important;
}

.standard-nav-icon {
    width: 1.7em;
    height: 1.7em;
    margin-right: 0.5em;
}

.navbar-brand {
    margin-right: 2em !important;
    display: flex !important;
    align-items: center !important;
}

.nav-link {
    display: flex !important;
    align-items: center !important;
    margin-right: 1em !important;
    color: white !important;
    width: fit-content !important;
}

.nav-link:hover {
    border-bottom: 1px solid white !important;
}

.nav-link.clicked {
    border-bottom: 1px solid white !important;
}

.nav-link a:hover {
    color: white !important;
}

.nav-item {
    display: flex !important;
    align-items: center !important;
    margin-right: 1em !important;
}

.white-nav-text {
    color: black !important;
}

.dropdown-item:active {
    background-color: white !important;
}

.logout-button-link:hover {
    border-bottom: 0px !important;
}

@media screen and (max-width: 1200px) {
    .container {
        justify-content: space-around !important;
    }
}