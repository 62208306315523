.access-app-header {
    margin-top: 3em;
    display: flex;
    justify-content: space-around;
}

.app-adding-modal-name-input {
    width: 100%;
}

.app-adding-modal-form {
    margin-top: 2em;
}

.app-adding-modal-buttons {
    margin-top: 3em;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1em;
}

@media screen and (max-width: 600px) {
    .access-app-header {
        flex-direction: column;
        gap: 1em;
    }

    .access-app-header h2 {
        font-size: 5vw;
    }

    .access-app-header button {
        font-size: 3vw;
        width: 80%;
    }
}