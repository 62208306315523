.formcontrol-setting {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.holiday-no-selection {
    margin-bottom: 2em;
}

.holiday-set-hours-minutes {
    margin-bottom: 10px;
}