.month-container {
    /* margin-left: 30%;
    width: 40%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.month-details {
    margin-top: 10px;
}

.set-buttongroup {
    margin-bottom: 20px;
}

.year-select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.year-select-formcontrol {
    width: 200px;
}

.vacation-select-formcontrol {
    width: 50%;
    margin-bottom: 20px !important;
}

.healthIcon {
    filter: invert() !important;
}

.hover-cursor-click {
    cursor: pointer;
}

.detail-view-calendar .rmdp-wrapper, .rmdp-calendar{
    width: 100%;
}

.detail-view-calendar .rmdp-day-picker {
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .parent-list-all-months {
        width: 90% !important;
    }
}