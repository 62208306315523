.past-future-list {
    margin-top: 50px;
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.list-items {
    width: 50%;
}

.active-message-list {
    width: 70%;
    background-color: #f5f5f5;
}