.employee-detail-headline {
    text-align: center;
}

.tab-group-container {
    font-size: 4em;
}

.tab-group-container .MuiTabs-indicator {
    background-color: var(--tictrack-primary-color) !important;
}

.tab-group-container button {
    font-size: 20px;
    text-transform: none;
    color: black !important;
}

.tab-group-container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: black !important;
}

.tab-group-container button:focus {
    outline: none;
}

.employee-information-header-container {
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    margin-top: 3%;
    align-items: baseline;
}

.tab-group-container.desktop {
    display: block;
}

.tab-group-container.mobile {
    display: none;
}

.employee-information-header-container p {
    font-size: 1.5em;
    margin-left: 3%;
}

.csv-export-container {
    margin-left: auto;
    margin-right: 3%;
}

.employee-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5em;
}

.employee-csv-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.employee-app-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 1em 1em 1em 1em;
    padding: 1em;
    margin-bottom: 5em;
    padding-left: 2em;
    padding-top: 2em;
    padding-bottom: 4em;
    gap: 0.6em;
}

.shifts-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .employee-information-header-container {
        margin-top: 10%;
    }

    .employee-detail-headline {
        margin-top: 5%;
    }
}

@media screen and (max-width: 1000px) {
    .tab-group-container.desktop {
        display: none;
    }
    
    .tab-group-container.mobile {
        display: block;
        align-items: center;
    }

    .MuiTabs-flexContainerVertical {
        align-items: center;
    }
}

@media screen and (max-width: 600px) {
    .employee-information-header-container {
        margin-top: 15%;
    }

    .employee-detail-headline {
        margin-top: 12%;
        font-size: 8vw;
    }
}