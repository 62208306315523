.location-header-container {
    margin-top: 4%;
    display: flex;
    margin-left: 9%;
    align-items: center;
}

.header-container {
    display: flex;
    justify-content: center;
    margin-right: 15px;
}

.search-bar-location-container {
    font-size: 1em !important;
    width: 20em !important;
    margin-left: 5em;
    border: 1px solid gainsboro;
    padding: 0.2em;
    border-radius: 1em 1em 1em 1em;
}

.search-bar-location-container .form-control {
    border: 0 !important;
}

.search-bar-location-container .form-control:focus {
    box-shadow: none !important;
}

.search-bar-location-container .input-group-text {
    border: 0 !important;
}

@media only screen and (max-width: 700px) {
    .location-header-container {
        margin-top: 10%;
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
    }

    .location-header-container h1 { 
        font-size: 150%;
    }

    .search-bar-location-container {
        /* margin-top: 3em; */
        margin-bottom: 20px;
        margin-left: 0;
        width: 80% !important;
        font-size: 3vw !important;
    }
}