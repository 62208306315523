.absence-creation-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 4em;
}

.create-absence-modal {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.set-hours-minutes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

.set-buttongroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.red {
    color: red;
}