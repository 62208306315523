.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50em;
    flex-direction: column;
    gap: 1em;
}

.error-page-container a {
    text-decoration: none;
}