.create-shift-header-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 3em;
    margin-left: 7em;
    gap: 3em;
}

.create-shift-header-container a {
    height: fit-content;
}

.create-shift-header-container h2 {
    margin-bottom: 0;
}

.create-shift-content-container {
    display: flex;
    flex-direction: row;
    margin-left: 7em;
    gap: 23em;
    margin-top: 5em;
    height: fit-content;
    padding-bottom: 50em;
}

.create-shift-begin-input-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    height: 10em;
    width: 100%;
}

.rs-steps-item-status-process .rs-steps-item-content {
    color: black !important;
    width: fit-content;
    cursor: pointer;
}

.rs-steps {
    width: 30em;
}

.rs-steps .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: var(--tictrack-secondary-color) !important;
    border-color: var(--tictrack-secondary-color) !important;
}

.rs-steps .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: var(--tictrack-secondary-color) !important;
    color: black !important;
}

.rs-steps-item-tail {
    border-color: var(--tictrack-secondary-color) !important;
}

.rs-steps-vertical {
    width: fit-content;
}

.rs-steps-item-content {
    color: darkgrey !important;
    cursor: pointer;
}

.rs-steps-item-content:hover {
    color: black !important;
}

.create-shift-begin-input-navigation-container {
    display: flex;
    justify-content: center;
    margin-top: 10em;
    width: 80%;
}

.create-shift-begin-input-navigation-container button {
    width: 100%;
}

.no-selection {
    border: 2px solid red;
}

@media only screen and (max-width: 1200px) {
    .create-shift-header-container {
        margin-left: 0;
        justify-content: center;
        gap: 1em;
    }

    .create-shift-header-container .btn {
        font-size: 2vw !important;
    }

    .create-shift-header-container h3 {
        font-size: 3vw !important;
    }

    .create-shift-content-container {
        display: flex;
        flex-direction: column;
        margin-left: 15vw;
        gap: 5em;
    }

    .create-shift-begin-input-container {
        font-size: 1.5vw;
        margin-top: 1vw;
        align-items: flex-start;
    }

    .rs-steps {
        width: 80%;
        font-size: 0.5vw;
    }

    .rs-steps-vertical {
        display: flex;
        flex-direction: row;
        width: 80%;
    }

    .rs-speps-item {
        display: none;
    }

    .rs-steps-item-content {
        display: none;
    }

    .rs-steps-item-title {
        display: none;
    }

    .rs-steps-item-description {
        display: none;
    }

    .rs-steps-vertical .rs-steps-item {
        font-size: 2vw;
    }

    .rs-steps-item-title {
        font-size: 2.5vw;
    }

    .rs-steps-item-status-process .rs-steps-item-content {
        width: 100%;
        font-size: 2.2vw;
    }

    .rs-steps-item-content {
        width: 70%;
    }
}