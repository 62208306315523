.app-connection-container {
    margin-top: 3em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1em;
    cursor: default;
    background-color: whitesmoke;
    width: 70em;
    height: fit-content;
}

.app-connection-container h5 {
    margin-top: 0 !important;
}

.app-connection-button-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 14em;
    text-decoration: none !important;
}

.pink-color {
    border-left: 5px solid #ffc0cb;
}

.green-color {
    border-left: 5px solid #33b864;
}

.gray-color {
    border-left: 5px solid #9E9E9E;
}

.gray-color h5 {
    text-decoration: line-through;
}

.gray-color h6 {
    text-decoration: line-through;
}

@media screen and (max-width: 1200px) {
    .app-connection-container {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .app-connection-container {
        flex-direction: column;
        font-size: 3vw;
        width: 90%;
        gap: 2em;
    }

    .app-connection-container h5 {
        font-size: 3vw;
    }

    .app-connection-container h6 {
        font-size: 2.5vw;
    }

    .app-connection-button-container {
        width: 60%;
    }

    .app-connection-button-container button {
        font-size: 3vw;
    }
}