.live-list-container {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .live-list-container {
        margin-top: 10%;
    }
}