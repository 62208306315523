.shift-complaints-container {
    margin-top: 3em;
    width: 23em;
}

.red-border {
    border: 0.3em solid red;
}

.shift-complaints-container .card-body {
    padding-top: 0;
}

.shift-complaints-container .css-1aquho2-MuiTabs-indicator {
    bottom: 10px;
}

.shift-complaints-container .MuiTab-root:focus {
    outline: none;
} 