.shift-header-container {
    margin-bottom: 5%;
}

.shift-container {
    background-color: whitesmoke;
    border-radius: 1.5em 1.5em 1.5em 1.5em;
    height: 13em;
    margin-bottom: 30px;
    cursor: pointer;
    width: 50%;
}

.shift-month-list p {
    margin-bottom: 0;
}

.shift-container a {
    color: black;
}

.not-completed-shift {
    border: solid 2px #DE5757;
}

.shift-container:hover {
    background-color: whitesmoke;
}

.shifts-overview-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 3em;
}

.new-shift-icon {
    width: 3em;
    height: 3em;
}

.add-mui-icon {
    box-shadow: none !important;
}

.shift-month-list {
    width: 50%;
    background-color: #f5f5f5;
}

.icon {
    width: 1.5em;
    height: 1.5em;
}

@media only screen and (max-width: 1024px) {
    .shift-container {
        width: 90%;
    }

    .shift-month-list {
        width: 80%;
    }
}

@media only screen and (min-width: 2500px) {
    .shift-container {
        height: 18em;
    }
}