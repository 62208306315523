.holiday-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.holiday-view-container .holiday-view-list {
    margin-top: 2em;
    width: 80em;
}

.year-select {
    margin-top: 3em;
}

.gray {
    color: gray !important;
}

.set-hours-minutes {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1300px) {
    .holiday-view-container .holiday-view-list {
        width: 80%;
    }
}