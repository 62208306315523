.app-connection-list-container {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-connection-list-container h5 {
    margin-top: 2em;
}

.app-disabled-connection-container {
    margin-top: 5em;
    width: 80em;
}

.app-disabled-connection-container h5 {
    margin-top: 1em;
}

@media screen and (max-width: 1300px) {
    .app-disabled-connection-container {
        width: 80%;
    }
}