.location-item-container {
    display: flex;
    margin-top: 0.5%;
    border-left: 1px solid #343A40;
    margin-left: 7%;
    margin-right: 7%;
    font-size: 1.5em;
    cursor: pointer;
    align-items: center;
    height: 2em;
}

.location-item-container:hover {
    background-color: whitesmoke;
}

.location-item-container p {
    text-align: left;
    margin-bottom: 0;
    margin-left: 1em;
}

.mobile-location-item-container {
    cursor: not-allowed;
    background-color: white !important;
}

.small-text {
    color: gray;
    font-size: 0.9em;
}

.black {
    color: black;
}