.full-generated-activity-time-Input {
    margin-left: 5%;
    width: 37em;
    margin-bottom: 0;
}

.full-generated-activity-editable-button {
    margin-left: 1%;
}

.error-in-time-validation {
    margin-left: 10%;
    color: red;
}

@media only screen and (max-width: 1200px) {
    .full-generated-activity-time-Input {
        margin-left: 5%;
        font-size: 3vw;
    }
    
    .full-generated-activity-editable-button {
        font-size: 2.5vw;
    }

    .full-generated-activity-modal .modal-title.h4 {
        font-size: 4vw;
    }

    .full-generated-activity-modal .modal-input {
        height: 6vw;
        width: 20vw;
    }


}