.shift-summary-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.shift-summary-container .card {
    width: 23em;
}

.summary-information-container {
    display: flex;
    gap: 1em;
    margin-bottom: 1em;
    justify-content: center;
    width: 100%;
}

.summary-information-container p {
    margin-bottom: 0;
}

.save-manual-time-button {
    margin-top: 1em;
}