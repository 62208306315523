.create-card-access-header-container {
    display: flex;
    align-items: center;
    margin-top: 3em;
    margin-left: 7em;
    gap: 3em;
}

.create-card-access-content-container {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.create-card-access-content-container input {
    width: 50em;
}

.create-card-access-content-container button {
    width: 50em;
}

.create-card-access-image {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2em;
}

.create-card-access-image h5 {
    width: 40em;
}

.create-card-access-image img {
    width: 40em;
}

@media screen and (max-width: 1000px) {
    .create-card-access-header-container {
        flex-direction: column;
        align-items: baseline;
        margin-left: 10%;
    }

    .create-card-access-content-container input {
        width: 75vw;
    }
    
    .create-card-access-content-container button {
        width: 80%;
    }

    .create-card-access-image img {
        width: 90%;
    }

    .create-card-access-image h5 {
        width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .create-card-access-header-container h3 {
        font-size: 4vw;
    }
}