.work-time-planning-page {
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.work-time-planning-header {
    margin-left: 9%;
    display: flex;
    align-items: center;
    gap: 5em;
}

.planning-table-today-header {
    color: var(--tictrack-primary-color);
}

.work-time-planning-next-back-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.work-time-planning-next-back-buttons p {
    margin-bottom: 0;
}

.work-time-planning-table {
    margin-top: 2em;
    margin-left: 5%;
    margin-right: 1em;
    /* border: 2px solid var(--tictrack-secondary-color); */
    width: 80%;
}

.work-time-planning-table tr, th, td {
    border: 2px solid var(--tictrack-secondary-color);
    padding: 10px;
    width: 5%;
}

.rs-picker-daterange-menu {
    display: none !important;
}

.work-time-planning-save-button {
    margin-left: 5%;
    margin-top: 2em;
    display: flex;
    gap: 3em;
}

.not-visible-info {
    margin-left: 9%;
    visibility: hidden;
}

.planning-information-section {
    margin-left: 5%;
    margin-right: 1em;
}

.planning-information-section p {
    margin-bottom: 0;
}

.empty-cell {
    width: 8em;
}

.bold {
    font-weight: bold;
}

.double-border-top {
    border-top: 6px double #000 !important; /* Adjust the color and width to your needs */
}

@media screen and (max-width: 1310px) {
    .work-time-planning-table {
        visibility: hidden
    }

    .work-time-planning-next-back-buttons {
        visibility: hidden;
    }

    .planning-information-section {
        visibility: hidden;
    }

    .not-visible-info {
        visibility: visible;
    }

    .work-time-planning-today-button {
        visibility: hidden;
    }
}

@media print {
    @page {
        size: landscape;
        margin: 1cm;
    }

    body * {
        visibility: hidden;
    }

    .work-time-planning-page, .work-time-planning-page * {
        visibility: visible;
    }

    .work-time-planning-page {
        position: absolute;
        left: 0;
        top: 0;
        width: max-content;
    }

    .work-time-planning-header, .not-visible-info, .planning-information-section {
        display: none;
    }

    .work-time-planning-save-button {
        display: none;
    }

    .double-border-top, .target-working-time-display, .weekly-total-time, .worktime-table-dailytotal, .abwesend-checkbox, .worktime-table-weeklytotal{
        display: none;
    }

    /* Style every second row with grey background */
    .work-time-planning-page table tr:nth-child(even) {
        background-color: rgb(219, 219, 219) !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}