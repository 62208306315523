.information-header-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 3em;
    margin-bottom: 4em;
}

.information-data-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 10%;
    padding-right: 10%;
    gap: 1em;
    width: 80%;
    margin-bottom: 3em;
}

.information-data-adress-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 2em;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
    gap: 1em;
    /*margin-bottom: 3em; added to information-data-container*/
}

.information-adress-horizontal-container {
    display: flex;
    width: 100%;
}

.modal-form-element p {
    margin-left: 10%;
}

.modal-form-element input {
    margin-left: 10%;
    width: 60%;
}

.save-employee-information-button {
    width: 60%;
    background-color: var(--tictrack-primary-color);
    color: white;
}

.save-employee-information-button:hover {
    background-color: var(--tictrack-secondary-color);
    color: white;
}

.adding-automated-pause-checkbox {
    color: #ae8704;
}

@media screen and (max-width: 1000px) {
    .information-header-container {
        flex-direction: column;
        width: 80%;
        gap: 1em;
    }

    .information-data-container {
        gap: 2em;
        width: 100%;
    }

    .information-data-adress-container {
        gap: 2em;
        width: 100%;
    }

    .save-employee-information-button {
        width: 80%;
    }
}