.create-shift-input {
    display: flex;
    flex-direction: row;
    gap: 1em;
    color: white;
    padding: 0.7em 1em;
    border-radius: 1em 1em 1em 1em;
    cursor: pointer;
    width: 100%;
}

.create-shift-input:hover {
    background-color: lightsalmon;
}

.create-shift-input h4 {
    margin: 0;
}

.create-shift-input input {
    cursor: pointer;
    border-radius: 0.4em 0.4em 0.4em 0.4em;
    border: none;
}

.create-shift-generator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.generated-shift {
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    width: 60%;
    padding: 0.7em;
    border-radius: 1em 1em 1em 1em;
    align-items: center;
}

.generated-pseudo-shift {
    display: flex;
    flex-direction: row;
    background-color: aliceblue;
    color: gray;
    padding: 0.7em 7.3em;
    border-radius: 1em 1em 1em 1em;
    align-items: baseline;
    cursor: pointer;
}

.generated-pseudo-shift:hover {
    color: black;
    background-color: lightcyan;
}

.generated-pseudo-shift h5 {
    margin: 0;
}

@media only screen and (max-width: 1500px) {
    .create-shift-generator-container {
        align-items: center;
    }
}

@media only screen and (max-width: 1200px) {
    .create-shift-generator-container {
        margin-top: 2em;
        width: 80%;
    }

    .create-shift-generator-container button {
        width: 30%;
        font-size: 2vw;
    }

    .generated-shift {
        width: 100%;
        padding: 0.7em;
    }

    .generated-pseudo-shift {
        font-size: 3vw;
    }

    .generated-pseudo-shift h5 {
        width: 100%;
        font-size: 0.8em;
    }
}