.live-item-header {
    font-size: 1.8em;
    font-weight: 500;
    text-align: left;
    margin-left: 10%;
}

.live-item-container {
    margin-bottom: 2.5%;
}

.live-employee-container {
    display: flex;
    background-color: whitesmoke;
    margin-left: 7%;
    margin-right: 7%;
    font-size: 1.5em;
    cursor: default;
    padding-left: 2%;
    margin-top: 0.5%;
    border-radius: 0.6em 0.6em 0.6em 0.6em;
    align-items: center;
    min-height: 2em;
}

.live-employee-container p {
    margin-bottom: 0;
    margin-left: 2%;
}

.circle {
    border-radius: 50%;
    width: 0.9em;
    height: 0.9em;
}

.lighter-text {
    font-size: 0.8em;
    font-weight: 400;
    color: gray;
}

.live-name {
    font-size: 1.5em;
    padding-left: 5%;
}

.live-name p {
    margin-bottom: 0;
}

@media screen and (max-width: 700px) {
    .live-item-header {
        font-size: 4.5vw;
    }

    .live-employee-container {
        font-size: 3.5vw;
    }
}