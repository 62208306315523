.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50em;
    width: 100%;
    margin-bottom: -5em;
}

.login-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em;
    border: 2px solid #000000; 
    position: relative;
    background-color: white;    
}

.login-text {
    text-align: center;
    margin-bottom: 15%;
}

.mb-3 {
    width: 50% !important;
    margin: 0 auto;
    margin-bottom: 1% !important;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    width: 100%;
    margin-top: 3em;
}

.login-button {
    background-color: var(--tictrack-primary-color) !important;
    border-color: #78B4D8 !important;
    margin-top: 2.5em;
    width: 50%;
    height: 3em;
    border-radius: 0.6em 0.6em 0.6em 0.6em !important;
    text-transform: none !important;
    font-size: large !important;
}

.login-button:hover {
    background-color: var(--tictrack-secondary-color) !important;
}

.login-error-message {
    margin-top: 3%;
    color: red;
    text-align: center;
}

/* @media screen and (max-width: 1200px) {
    .login-container {
        height: auto;
    }
}
 */
@media screen and (max-width: 500px) {
    .login-field{
        width: 100%;
        height: auto;
        border: none;
    }

    .login-container {
        height: auto;
    }

    .login-text {
        font-size: xx-large;
    }
}
