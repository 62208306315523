#ChangeDisplay {
    margin-top: 5%;
    margin-left: 10%;
    margin-bottom: 10%;
}

.header-information-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10%;
    margin-top: 8%;
}

.header-information-container button {
    width: auto;
    font-size: 1em;
}

.MuiButtonBase-root {
    text-transform: none !important;
}

.location-information-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    width: 100%;
    margin-bottom: 4em;
}

.location-information-input {
    width: 80%;
}

.activate-deactivate-button-container {
    margin-left: 10%;
    margin-bottom: 10%;
}

.activate-deactivate-button-container button {
    font-size: 1em;
}

.modal-text {
    margin-top: 5%;
}

@media screen and (max-width: 1200px) {
    .header-information-container {
        flex-direction: column;
        gap: 1em;
    }
}

@media screen and (max-width: 1000px) {
    .header-information-container h1 {
        font-size: 4vw;
    }
}

@media screen and (max-width: 500px) {
    .header-information-container h1 {
        font-size: 6vw;
    }
}