.rs-picker-menu {
    z-index: 99999999;
}

.time-Input {
    margin-left: 5%;
    width: 15em;
    margin-bottom: 0;
}

.rs-picker-toggle-value {
    color: black !important;
}

.edit-time-input {
    width: 100% !important;
    margin-bottom: 3em;
}

.editable-button {
    margin-left: 1%;
}

.edit-reason-switch-container {
    margin-bottom: 2em !important;
}

.other-reason-textarea {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .edit-time-input {
        width: 90%;
    }
}