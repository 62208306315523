.create-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.create-modal-body p{
    margin-bottom: 0px;
}

.create-modal-body a{
    color: white;
}

.create-modal-body button{
    margin-bottom: 20px;
}

.create-modal-body .menu-items {
    transition-duration: 0s !important;
    transform: none;
}

.warning-text-overlap {
    text-align: center;
    color: orange;
}