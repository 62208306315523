.impressum-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    margin-bottom: 10em;
    margin-left: 7em;
    margin-right: 5em;
}

.impressum-container h1 {
    margin-top: 0.5em;
    color: var(--tictrack-gray-color);
}

.impressum-container button {
    width: 10em;
    margin-bottom: 3em;
}

@media screen and (max-width: 1000px) {
    .impressum-container {
        margin-left: 5%;
        margin-right: 5%;
    }
}