.dashboard-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.full-width-content-container {
    margin-top: 3em;
    background-color: whitesmoke;
    border-radius: 2em;
    width: 95%;
}

.half-width-content-container {
    margin-top: 3em;
    background-color: whitesmoke;
    border-radius: 2em;
    width: 45%;
}

.quarter-width-content-container {
    margin-top: 3em;
    background-color: whitesmoke;
    border-radius: 2em;
    width: 20%;
}

@media screen and (max-width: 1200px) {
    .quarter-width-content-container {
        width: 45%;
    }
}

@media screen and (max-width: 1000px) {
    .half-width-content-container {
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .quarter-width-content-container {
        width: 95%;
    }
}