.work-time-account-container {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 2em;
}

.work-time-account-header {
    margin-bottom: 1em;
}

.work-time-account-header .horizontal-header {
    display: flex;
    justify-content: space-between;
}

.work-time-account-header .horizontal-header button {
    margin-right: 1em;
}

.work-time-account-header .MuiButtonBase-root {
    padding-left: 0;
}

.work-time-account-container .MuiListItemText-secondary {
    font-weight: bold !important;
}

.work-time-account-container .red-list-item .MuiListItemText-secondary {
    color: red;
}

.work-time-account-container .green-list-item .MuiListItemText-secondary {
    color: green;
}

.work-time-account-container a {
    color: black;
}

.red-background {
    color: white !important;
    background-color: red !important;
}